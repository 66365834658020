import React from "react";
import { Switch, Route, Redirect } from "react-router-dom";
import Test1 from "./screens/Test1";
import Test2 from "./screens/Test2";

const App = () => {
  return (
    <Switch>
      <Route path="/" render={() => <Redirect to="/test1" />} exact />
      <Route path="/test1" component={Test1} exact />
      <Route path="/test2" component={Test2} exact />
    </Switch>
  );
};

export default App;
