import React, { useState } from 'react';
import _ from 'lodash';
import {
  Box,
  Button,
  Dialog,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Divider,
  FormControlLabel,
  Paper,
  Radio,
  Snackbar,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography
} from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import Layout from '../components/Layout';
import questions from '../configs/questions2.json';
import personality from '../configs/personality2.json';
import { test2State } from '../example';

const useStyles = makeStyles(theme => ({
  divider: {
    marginBottom: '8px'
  },
  paper: theme.paper,
  row: theme.row,
  resultTable: {
    position: 'fixed',
    bottom: '16px',
    right: '16px',
    maxHeight: '400px',
    overflow: 'scroll'
  },
  answerRow: {
    padding: '0px 8px'
  },
  sumRow: {
    backgroundColor: '#EEE'
  },
  grow: {
    flexGrow: 1
  }
}));

const Test2 = () => {
  const [results, setResults] = useState({});
  const { divider, paper, resultTable, row } = useStyles();

  return (
    <Layout>
      <Paper className={paper}>
        <div className={row}>
          <Typography variant="h6">แบบที่ 2</Typography>
        </div>
        <Divider className={divider} />
        {questions.map((item, index) => (
          <Question
            key={index}
            index={index}
            question={item.question}
            answer0={item.answer0}
            answer1={item.answer1}
            answer2={item.answer2}
            results={results}
            setResults={setResults}
          />
        ))}
      </Paper>
      <div className={resultTable}>
        <ResultTable results={results} />
      </div>
    </Layout>
  );
};

const Question = props => {
  const [showError, setShowError] = useState(false);
  const { row, answerRow, grow } = useStyles();

  const { question, index, results, setResults } = props;
  const handleChange = e => {
    const match = Object.entries(results).find(entry => {
      if (entry[0].startsWith(`q-${index}`) && entry[1] === e.target.value) {
        return true;
      }
    });
    if (match && match[0] !== e.target.name) {
      setShowError(true);
      return;
    }
    setResults({ ...results, [e.target.name]: e.target.value });
  };

  const reset = () => {
    const cloned = _.cloneDeep(results);
    Object.keys(cloned).forEach(key => {
      if (key.startsWith(`q-${index}`)) {
        delete cloned[key];
      }
    });
    setResults(cloned);
  };

  return (
    <>
      <div className={row}>
        <Typography>
          {index + 1}. {question}
        </Typography>
        <div className={grow} />
        <Button
          variant="contained"
          color="secondary"
          size="small"
          onClick={() => reset()}
        >
          เริ่มใหม่
        </Button>
      </div>
      {[0, 1, 2].map(answerIndex => (
        <div key={answerIndex} className={answerRow}>
          <FormControlLabel
            control={
              <Radio
                color="primary"
                checked={results[`q-${index}-${answerIndex}`] === '2'}
                onChange={handleChange}
                value={2}
                name={`q-${index}-${answerIndex}`}
              />
            }
            label={<Typography variant="caption">A</Typography>}
            labelPlacement="end"
          />
          <FormControlLabel
            control={
              <Radio
                color="default"
                checked={results[`q-${index}-${answerIndex}`] === '1'}
                onChange={handleChange}
                value={1}
                name={`q-${index}-${answerIndex}`}
              />
            }
            label={<Typography variant="caption">B</Typography>}
            labelPlacement="end"
          />
          <FormControlLabel
            control={
              <Radio
                color="secondary"
                checked={results[`q-${index}-${answerIndex}`] === '0'}
                onChange={handleChange}
                value={0}
                name={`q-${index}-${answerIndex}`}
              />
            }
            label={<Typography variant="caption">X</Typography>}
            labelPlacement="end"
          />
          <Typography>{props[`answer${answerIndex}`]}</Typography>
        </div>
      ))}
      <Snackbar
        message="ไม่สามารถเลือกคำตอบซ้ำกันได้"
        autoHideDuration={2000}
        open={showError}
        onClose={() => setShowError(false)}
      />
    </>
  );
};

const ResultTable = ({ results }) => {
  const [showDialog, setShowDialog] = useState(false);
  const { sumRow } = useStyles();

  const sumColumn = col => {
    let sum = 0;
    for (let i = 0; i < 18; i++) {
      sum += parseInt(results[`q-${i}-${col}`] || 0);
    }
    return sum;
  };
  const getPersonalities = () => {
    const sums = [0, 1, 2].map(col => ({ index: col, sum: sumColumn(col) }));
    const sorted = sums.sort((a, b) => b.sum - a.sum);
    return sorted;
  };
  return (
    <Paper>
      <Table padding="default">
        <TableHead>
          <TableRow>
            <TableCell>
              <Typography>ข้อที่</Typography>
            </TableCell>
            <TableCell>
              <Typography>ความถนัดแบบ D</Typography>
            </TableCell>
            <TableCell>
              <Typography>ความถนัดแบบ P</Typography>
            </TableCell>
            <TableCell>
              <Typography>ความถนัดแบบ T</Typography>
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {[0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17].map(
            row => (
              <TableRow key={row}>
                <TableCell align="center">{row + 1}</TableCell>
                {[0, 1, 2].map(col => (
                  <TableCell key={col} align="center">
                    {results[`q-${row}-${col}`] || ''}
                  </TableCell>
                ))}
              </TableRow>
            )
          )}
          <TableRow className={sumRow}>
            <TableCell align="center">รวม</TableCell>
            {[0, 1, 2].map(col => (
              <TableCell key={col} align="center">
                {sumColumn(col)}
              </TableCell>
            ))}
          </TableRow>
        </TableBody>
      </Table>
      <Button
        variant="contained"
        fullWidth
        size="large"
        color="primary"
        disabled={Object.keys(results).length < 54}
        onClick={() => setShowDialog(true)}
      >
        ดูผลลัพธ์
      </Button>
      <ResultDialog
        showDialog={showDialog}
        setShowDialog={setShowDialog}
        ordered={getPersonalities()}
      />
    </Paper>
  );
};

const ResultDialog = ({ showDialog, setShowDialog, ordered }) => (
  <Dialog open={showDialog} onClose={() => setShowDialog(false)}>
    <DialogTitle>บุคลิกของคุณเรียงตามคะแนน</DialogTitle>
    <DialogContent>
      {ordered.map((item, index) => (
        <Box key={index}>
          <Typography variant="h6" paragraph>
            {personality[item.index].type} {item.sum} คะแนน
          </Typography>
          <Typography variant="body2" style={{ fontWeight: 'bold' }} paragraph>
            ลักษณะโดยทั่วไป
          </Typography>
          <Typography variant="body2" paragraph>
            {personality[item.index].overview}
          </Typography>
          <Typography variant="body2" style={{ fontWeight: 'bold' }} paragraph>
            อาชีพที่สอดคล้องกับความถนัด
          </Typography>
          <Typography variant="body2" paragraph>
            {personality[item.index].occupation}
          </Typography>
        </Box>
      ))}
    </DialogContent>
  </Dialog>
);

export default Test2;
