import React, { useState } from 'react';
import {
  Box,
  Button,
  Dialog,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Divider,
  FormControlLabel,
  Paper,
  Radio,
  RadioGroup,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography
} from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import Layout from '../components/Layout';
import questions from '../configs/questions1.json';
import personality from '../configs/personality1.json';
import { test1State } from '../example';

const useStyles = makeStyles(theme => ({
  divider: {
    marginBottom: '8px'
  },
  paper: theme.paper,
  row: theme.row,
  resultTable: {
    position: 'fixed',
    bottom: '16px',
    right: '16px'
  },
  sumRow: {
    backgroundColor: '#EEE'
  }
}));

const Test1 = () => {
  const [results, setResults] = useState({});
  const { divider, paper, resultTable, row } = useStyles();

  const handleChange = e => {
    setResults({ ...results, [e.target.name]: e.target.value });
  };
  return (
    <Layout>
      <Paper className={paper}>
        <div className={row}>
          <Typography variant="h6">แบบที่ 1</Typography>
        </div>
        <Divider className={divider} />
        {questions.map((q, index) => (
          <div key={index}>
            <div className={row}>
              <Typography>
                {index + 1}. {q}
              </Typography>
            </div>
            <RadioGroup
              name={`q-${index}`}
              className={row}
              onChange={handleChange}
            >
              <FormControlLabel
                value="2"
                control={<Radio />}
                label="ชอบหรือต้องการ"
              />
              <FormControlLabel
                value="1"
                control={<Radio />}
                label="คิดดูก่อนหรือไม่แน่ใจ"
              />
              <FormControlLabel
                value="0"
                control={<Radio />}
                label="ไม่ชอบหรือไม่ต้องการ"
              />
            </RadioGroup>
          </div>
        ))}
      </Paper>
      <div className={resultTable}>
        <ResultTable results={results} />
      </div>
    </Layout>
  );
};

const ResultTable = ({ results }) => {
  const [showDialog, setShowDialog] = useState(false);
  const { sumRow } = useStyles();

  const sumColumn = col => {
    let sum = 0;
    for (let i = 0; i < 9; i++) {
      sum += parseInt(results[`q-${i * 6 + col}`] || 0);
    }
    return sum;
  };
  const getPersonalities = () => {
    const sums = [0, 1, 2, 3, 4, 5].map(col => ({
      index: col,
      sum: sumColumn(col)
    }));
    const sorted = sums.sort((a, b) => b.sum - a.sum);
    return sorted;
  };
  return (
    <Paper>
      <Table padding="default">
        <TableHead>
          <TableRow>
            <TableCell>
              <Typography>บุคลิกแบบ R</Typography>
            </TableCell>
            <TableCell>
              <Typography>บุคลิกแบบ I</Typography>
            </TableCell>
            <TableCell>
              <Typography>บุคลิกแบบ S</Typography>
            </TableCell>
            <TableCell>
              <Typography>บุคลิกแบบ C</Typography>
            </TableCell>
            <TableCell>
              <Typography>บุคลิกแบบ E</Typography>
            </TableCell>
            <TableCell>
              <Typography>บุคลิกแบบ A</Typography>
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {[0, 1, 2, 3, 4, 5, 6, 7, 8].map(row => (
            <TableRow key={row}>
              {[0, 1, 2, 3, 4, 5].map(col => (
                <TableCell key={col} align="center">
                  {results[`q-${row * 6 + col}`]}
                </TableCell>
              ))}
            </TableRow>
          ))}
          <TableRow className={sumRow}>
            {[0, 1, 2, 3, 4, 5].map(col => (
              <TableCell key={col} align="center">
                {sumColumn(col)}
              </TableCell>
            ))}
          </TableRow>
        </TableBody>
      </Table>
      <Button
        variant="contained"
        fullWidth
        size="large"
        color="primary"
        disabled={Object.keys(results).length < 54}
        onClick={() => setShowDialog(true)}
      >
        ดูผลลัพธ์
      </Button>
      <ResultDialog
        showDialog={showDialog}
        setShowDialog={setShowDialog}
        ordered={getPersonalities()}
      />
    </Paper>
  );
};

const ResultDialog = ({ showDialog, setShowDialog, ordered }) => (
  <Dialog open={showDialog} onClose={() => setShowDialog(false)}>
    <DialogTitle>บุคลิกของคุณเรียงตามคะแนน</DialogTitle>
    <DialogContent>
      {ordered.map((item, index) => (
        <Box key={index}>
          <Typography variant="h6" paragraph>
            {personality[item.index].type} ({item.sum} คะแนน)
          </Typography>
          <Typography variant="body2" style={{ fontWeight: 'bold' }} paragraph>
            ลักษณะโดยทั่วไป
          </Typography>
          <Typography variant="body2" paragraph>
            {personality[item.index].overview}
          </Typography>
          <Typography variant="body2" style={{ fontWeight: 'bold' }} paragraph>
            ลักษณะเด่นของบุคลิกภาพ
          </Typography>
          <Typography variant="body2" paragraph>
            {personality[item.index].outstanding}
          </Typography>
          <Typography variant="body2" style={{ fontWeight: 'bold' }} paragraph>
            อาชีพที่สอดคล้องกับบุคลิกภาพ
          </Typography>
          <Typography variant="body2" paragraph>
            {personality[item.index].occupation}
          </Typography>
          <Typography variant="body2" style={{ fontWeight: 'bold' }} paragraph>
            สาขาวิชาที่อาจเลือกศึกษา
          </Typography>
          <Typography variant="body2" paragraph>
            {personality[item.index].study}
          </Typography>
        </Box>
      ))}
    </DialogContent>
  </Dialog>
);

export default Test1;
