import React from 'react';
import { Link as RouterLink } from 'react-router-dom';
import { AppBar, Button, Toolbar, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';

const useStyles = makeStyles(theme => ({
  root: {
    width: '100%'
  },
  grow: {
    flexGrow: 1
  },
  layout: {
    width: 'auto',
    marginLeft: theme.spacing(3),
    marginRight: theme.spacing(3),
    [theme.breakpoints.up(1100 + theme.spacing(3 * 2))]: {
      width: 1100,
      marginLeft: 'auto',
      marginRight: 'auto'
    }
  }
}));

const Layout = ({ children }) => {
  const { root, grow, layout } = useStyles();
  return (
    <div className={root}>
      <AppBar position="static">
        <Toolbar>
          <Button component={RouterLink} to="/" color="inherit">
            <Typography variant="h6" color="inherit">
              Personality Test
            </Typography>
          </Button>
          <div className={grow} />
          <Button component={RouterLink} to="/test1" color="inherit">
            แบบที่ 1
          </Button>
          <Button component={RouterLink} to="/test2" color="inherit">
            แบบที่ 2
          </Button>
        </Toolbar>
      </AppBar>
      <div className={layout}>{children}</div>
    </div>
  );
};

export default Layout;
